import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import ContentWrapper from "../components/ContentWrapper"
import TitleDescriptionImage from "../components/slices/TitleDescriptionImage"
import QuoteSlice from "../components/slices/QuoteSlice"
import TextDescriptionColumns from "../components/slices/TextDescriptionColumns"
import StackColumnsContent from "../components/slices/StackColumnsContent"
import Intro from "../components/deepdive/deepdive-template/Intro"
import CTASlice from "../components/slices/CTASlice"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

const DeepdiveTemplate = ({ data }: any) => {
  const templateData = data.prismicDeepDiveTemplate.data

  const ctaSlices = templateData.body.filter(
    (slice: any) =>
      slice.slice_type === "team_member_v2" ||
      slice.slice_type === "services_cards" ||
      slice.slice_type === "resources_cards" ||
      slice.slice_type === "deep_dive_cards"
  )

  return (
    <Container>
      <ContentWrapperStyled>
        {/* HERO SECTION */}
        <Intro
          eyebrowHeading={templateData.eyebrow_heading}
          description={templateData.description}
          heading={templateData.heading.text}
          headingColor={templateData.heading_color}
          backgroundColor={templateData.background_color}
          foregroundColor={templateData.foreground_color}
        />

        {/* CONTENT SLICES */}
        <InnerWrapper>
          {templateData.body.map((slice: any) => (
            <>
              {slice.slice_type === "titledescriptionimage" && (
                <TitleDescriptionImage
                  key={slice.slice_type}
                  primary={{
                    align: slice.primary.align,
                    description1: slice.primary.description1.html,
                    title: slice.primary.title.raw,
                    subtitle: slice.primary.subtitle,
                    image_position: slice.primary.image_position,
                    image: slice.primary.image,
                  }}
                />
              )}
              {slice.slice_type === "quote" && (
                <QuoteSlice
                  key={slice.slice_type}
                  primary={{
                    title: slice.primary.title.raw,
                    subtitle: slice.primary.subtitle,
                  }}
                />
              )}
              {slice.slice_type === "textdescriptioncolumns" && (
                <TextDescriptionColumns
                  key={slice.slice_type}
                  primary={{
                    columnsPerRow: slice.primary.columns_per_row,
                  }}
                  items={slice.items.map((item: any) => ({
                    title: item.title.raw,
                    description1: item.description1.html,
                  }))}
                />
              )}
              {slice.slice_type === "stackcolumnscontent" && (
                <StackColumnsContent
                  key={slice.slice_type}
                  items={slice.items.map((item: any) => ({
                    image: item.image,
                    title: item.title.raw,
                    description1: item.description1.html,
                  }))}
                />
              )}
            </>
          ))}
        </InnerWrapper>

        {ctaSlices.map((slice: any) => (
          <CTASlice slice={slice} />
        ))}
      </ContentWrapperStyled>
    </Container>
  )
}

export const query = graphql`
  query deepdiveTemplate($uid: String!, $locale: String!) {
    prismicDeepDiveTemplate(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        description
        eyebrow_heading
        heading {
          text
        }
        heading_color
        foreground_color
        background_color
        body {
          ... on PrismicDeepDiveTemplateBodyTitledescriptionimage {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
              align
              description1 {
                html
              }
              image_position
              image {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
                alt
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyQuote {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyTextdescriptioncolumns {
            id
            slice_type
            primary {
              columns_per_row
            }
            items {
              description1 {
                html
              }
              title {
                raw
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyStackcolumnscontent {
            id
            slice_type
            items {
              description1 {
                html
              }
              title {
                raw
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  sizes
                }
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyDeepDiveCards {
            id
            slice_type
            primary {
              button_label
              button_link
              deep_dive_description
              deep_dive_heading {
                raw
                text
              }
            }
            items {
              deep_dive_template {
                id
                type
                uid
                document {
                  ... on PrismicDeepDiveTemplate {
                    id
                    data {
                      heading {
                        text
                        raw
                      }
                      description
                      heading_color
                      foreground_color
                      background_color
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyServicesCards {
            id
            slice_type
            primary {
              services_cards_button_label
              services_cards_button_link
              services_cards_heading {
                raw
              }
            }
            items {
              services_cards_template {
                document {
                  ... on PrismicServicesTemplate {
                    id
                    uid
                    data {
                      title
                      service_type
                      service_level
                      service_type_color
                      heading
                      description
                      thumbnail_image {
                        alt
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDeepDiveTemplateBodyResourcesCards {
            id
            slice_type
            primary {
              resources_cards_button_link
              resources_cards_heading {
                raw
              }
              resources_cards_label
            }
            items {
              resources_cards_template {
                document {
                  ... on PrismicResourceTemplate {
                    uid
                    data {
                      heading {
                        text
                      }
                      description {
                        text
                      }
                      image {
                        alt
                        url
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default DeepdiveTemplate

const Container = styled.section`
  position: relative;
  padding: 96px 0 80px;
  color: ${colors.text};

  ${mq.from.S`
    padding: 104px 0 80px;
  `} ${mq.from.M`
    padding: 168px 0 80px;
  `};
`

const ContentWrapperStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 0 16px;

  ${mq.from.S`
  padding: 0 48px;
`}

  ${mq.from.L`
    padding: 0 88px;
  `}
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  ${mq.from.L`
    padding: 0px 109px;
  `}
`
